<template>
    <div>
      <h1>Processing Payment</h1>
      <p v-if="loading">Loading...</p>
      <v-alert
        :value="successMessage"
        type="success"
        class="my-0"
      >Платеж успешно отправлен!</v-alert>
      <v-alert :value="errorMessage" type="error" class="my-0">
        {{errorAlert}}
      </v-alert>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        loading: true,
        errorMessage: false,
        successMessage: false,
        errorAlert: '',
        vtItem: {
          client_id: null,
          amount: null,
          bouquet_id:null,
          store_id: null,
          payment_type_id: 16, // Assuming this is the payment type ID
        },
      };
    },
    created() {
    // Get the query parameters from the route and convert them to integers
    const { client_id, amount, bouquet_id, store_id } = this.$route.query;

    // Set the parameters to vtItem as integers
    this.vtItem.client_id = parseInt(client_id, 10); // Convert to integer
    this.vtItem.amount = parseInt(amount, 10);       // Convert to integer
    this.vtItem.bouquet_id = parseInt(bouquet_id, 10);
    this.vtItem.store_id = parseInt(store_id, 10);   // Convert to integer

    // Call the payment API
    this.submitPayment();
    },
    methods: {
      submitPayment() {
        const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-vendor`; // Adjust the URL as needed
        console.log('this.vtItem', this.vtItem)
        axios
          .post(url, this.vtItem)
          .then(() => {
            this.successMessage = true;
            this.loading = false;
          })
          .catch((error) => {
            this.errorMessage = true;
            if (error.response) {
              if (error.response.status === 400 || error.response.status === 500) {
                  if (error.response.data && error.response.data.error) {
                    this.errorAlert = error.response.data.error;
                  }
              }
            }
          });
      },
    },
  };
  </script>